<template>
	<div data-component="app-header">
		<header-large />
		<header-small />
	</div>
</template>

<script>

	import HeaderLarge  from '@/components/ui/header/headerLarge/HeaderLarge';
	import HeaderSmall  from '@/components/ui/header/headerSmall/HeaderSmall';

	export default {
		components: {
			HeaderLarge,
			HeaderSmall
		}

	};
</script>

<style lang="scss" scoped>

</style>
