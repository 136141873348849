<template>
	<div
		data-component="drawer"
		:data-visible="getIsVisible"
	>
		<menu-button
			icon="cross"
		/>
		<div
			data-element="menu-items"
		>
			<drawer-item
				v-for="(menuItem, i) in getMenuItems"
				:key="i"
				v-bind="menuItem"
			/>
		</div>
	</div>
</template>

<script>

	import DrawerItem  from '@/components/ui/header/headerSmall/DrawerItem';
	import MenuButton  from '@/components/ui/header/headerSmall/MenuButton';

	export default {
		components: {
			DrawerItem,
			MenuButton
		},
		computed: {
			getIsVisible () {
				return this.$store.getters['ui/getDrawerIsVisible'];
			},
			getMenuItems () {
				return [
					{
						text: 'Find a course',
						route: '/browse-courses'
					},
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'My learning',
							route: '/my-courses'
						} : false
					),
					{
						text: 'About',
						route: '/about'
					},
					(this.$store.getters['auth/getIsTeacher'] ?
						{
							text: 'Teaching',
							route: '/teaching'
						} : false
					),
					(this.$store.getters['auth/getIsAdmin'] ?
						{
							text: 'Admin',
							route: '/admin'
						} : false
					),
					(!this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'Sign in',
							route: '/login'
						} : false
					),
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'My profile',
							route: '/my-profile'
						} : false
					),
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'Log out',
							action: 'auth/logOut'
						} : false
					)
				].filter(Boolean);
			}
		},
		mounted () {
			window.addEventListener('resize', this.onResize);
		},
		beforeDestroy () {
			window.removeEventListener('resize', this.onResize);
		},
		methods: {
			onResize () {
				const mq = window.matchMedia('(min-width:899px)');
				if (!mq?.matches) {
					return;
				}
				if (!this.getIsVisible) {
					return;
				}
				this.$store.commit('ui/setDrawerIsHidden');
			},
			toggleDrawer () {
				this.$store.commit('ui/toggleDrawerIsVisible');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='drawer'] {
		position:fixed;
		z-index:1;
		overflow-y:auto;
		top:0;
		left:100vw;
		width:320px;
		height:100vh;
		background-color:$c-white;
		transition:transform 0.6s ease;
		box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.72);
		&[data-visible] {
			transform:translate3d(-100%,0,0);
		}
		[data-component='menu-button']  {
			position:absolute;
			top:rem(8);
			right:rem(8);
			cursor:pointer;
		}
		[data-element='menu-items'] {
			display:flex;
			flex-direction:column;
			margin:rem(32) rem(48);
		}
	}
</style>
