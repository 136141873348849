<template>
	<div data-component="header-large">
		<app-logo />
		<nav-menu />
		<profile-button />
	</div>
</template>

<script>

	import AppLogo        from '@/components/ui/header/AppLogo';
	import NavMenu        from '@/components/ui/header/headerLarge/NavMenu';
	import ProfileButton  from '@/components/ui/header/headerLarge/ProfileButton';

	export default {
		components: {
			AppLogo,
			NavMenu,
			ProfileButton
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='header-large'] {
		width: 100%;
    height: 78px;
    background: $c-white;
    padding: 0 120px;

    display: flex;
    justify-content: space-between;
    align-items: center;
	}
</style>
